/* eslint-disable react/no-unstable-nested-components */
import { FC, memo } from 'react';
import { useRouter } from 'next/router';
import { IconChevronLeft, IconChevronRight } from '@nzxt/react-icons';
import ReactPaginate from 'react-paginate';
import { useTranslation as t, useMedia } from '@utils/hooks';
import * as styles from './styles';

type Props = {
  totalPages: number;
  currentPage: number;
  routePrefix: string;
};

const Pagination: FC<Props> = ({ totalPages, currentPage, routePrefix }) => {
  const isSm = useMedia('sm');

  const { push, asPath } = useRouter();

  const handlePageClick = ({ selected }): void => {
    push(`${routePrefix}/${selected + 1}`);
  };

  const hrefBuilder = (page: number): string => `${routePrefix}/${page}`;

  const PREVIOUS = t('previous');
  const NEXT = t('next');

  const testIdPrefix = 'pagination-navigation';

  const PreviousLabel = (): JSX.Element =>
    isSm ? (
      <IconChevronLeft />
    ) : (
      <span data-test-id={`${testIdPrefix}-previous`} className={styles.flexx}>
        <IconChevronLeft className={styles.prevIcon} />
        {PREVIOUS}
      </span>
    );

  const NextLabel = (): JSX.Element =>
    isSm ? (
      <IconChevronRight />
    ) : (
      <span data-test-id={`${testIdPrefix}-next`} className={styles.flexx}>
        {NEXT}
        <IconChevronRight className={styles.nextIcon} />
      </span>
    );

  return (
    <ReactPaginate
      key={asPath}
      previousLabel={totalPages > 1 && <PreviousLabel />}
      nextLabel={totalPages > 1 && <NextLabel />}
      hrefBuilder={hrefBuilder}
      breakLabel="..."
      breakClassName={styles.getLinkStyle(false)}
      pageCount={totalPages}
      initialPage={currentPage - 1}
      marginPagesDisplayed={1}
      pageRangeDisplayed={isSm ? 1 : 2}
      onPageChange={handlePageClick}
      containerClassName={styles.container}
      activeLinkClassName={styles.getLinkStyle(true)}
      pageClassName={styles.linkContainer}
      pageLinkClassName={styles.getLinkStyle(false)}
      previousClassName={styles.prevContainer}
      nextClassName={styles.nextContainer}
      previousLinkClassName={styles.prevNextLink}
      nextLinkClassName={styles.prevNextLink}
      disabledClassName={styles.hidden}
    />
  );
};

export default memo(Pagination);
