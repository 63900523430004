import { classnames } from '@tailwindcss-classnames';

export const container = classnames(
  'border-t',
  'border-gray-200',
  'px-4',
  'flex',
  'items-center',
  'justify-between',
  'sm:px-0',
  'my-6'
);

export const prevContainer = classnames('-mt-px', 'w-0', 'flex-1', 'flex');
export const prevNextLink = classnames(
  'border-t-2',
  'border-transparent',
  'pt-4',
  'pr-1',
  'inline-flex',
  'items-center',
  'text-sm',
  'font-medium',
  'text-gray-500',
  'hover:text-gray-700',
  'hover:border-gray-300',
  'focus:outline-none',
  'cursor-pointer'
);
export const nextContainer = classnames(
  '-mt-px',
  'w-0',
  'flex-1',
  'flex',
  'justify-end'
);

export const linkContainer = classnames('-mt-px', 'flex');
export const flexx = classnames('flex', 'items-center');
export const prevIcon = classnames('mr-2');
export const nextIcon = classnames('ml-2');
export const hidden = classnames('opacity-0', 'pointer-events-none');

const activeLink = classnames('border-nzxt-violet-300', 'text-nzxt-violet-400');
const inactiveLink = classnames(
  'border-transparent',
  'text-gray-500',
  'hover:text-gray-700',
  'hover:border-gray-300'
);

export const getLinkStyle = (isActive: boolean): string =>
  classnames(
    { [activeLink]: isActive, [inactiveLink]: !isActive },
    'border-t-2',
    'pt-4',
    'px-4',
    'inline-flex',
    'items-center',
    'text-sm',
    'font-medium',
    'focus:outline-none',
    'cursor-pointer'
  );
